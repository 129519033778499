import { combineLatest, forkJoin, from, of } from 'rxjs';
import { concatMap, filter, shareReplay, tap, toArray } from 'rxjs/operators';
import { gqlSystems$ } from '../system/systemStore.selectors';
import { activeSiteAreas$ } from './siteStore.selectors';

export const activeSiteAreaSystems$ = combineLatest({
  areas: activeSiteAreas$,
  systems: gqlSystems$,
}).pipe(
  concatMap(({ areas, systems }) => {
    if (!areas || !systems) {
      return of(null);
    }

    if (areas?.length === 0 || systems.length === 0) {
      return from([]).pipe(toArray());
    }
    return from(areas).pipe(
      concatMap((area) => {
        return forkJoin({
          area: of(area),
          systems: from(systems).pipe(
            filter((system) => system.areaId === area.id),
            toArray()
          ),
        });
      }),

      toArray()
    );
  })

  // shareReplay(1),
);
